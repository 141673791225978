<template>
  <div>
    <div class="banner" :class="{'banner_hover':is_owner}">
      <img :src="imageUrl" v-if="imageUrl">
      <img src="@/assets/images/account/banner.png" alt="" v-else>
      <template v-if="is_owner">
        <a-upload
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            accept="image/*"
            :before-upload="beforeUpload"
            :customRequest="backgroundRequest"
        >
          <div>
            <div class="ant-upload-text">
            </div>
          </div>
          <img src="@/assets/images/account/upload.svg" alt="" class="upload_img" v-if="!isMobile">
          <img src="@/assets/images/account/upload_mo.svg" alt="" class="upload_img" v-else>
        </a-upload>
      </template>
    </div>
    <div class="user_box">
      <div class="user_img" :class="{'user_img_hover':is_owner}">
        <img :src="imageUrl2" alt="" v-if="imageUrl2">
      <img src="@/assets/images/account/user_img.svg" alt="" v-else>
        <template v-if="is_owner">
          <a-upload
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              accept="image/*"
              :before-upload="beforeUpload"
              :customRequest="avatarRequest"
          >
            <div>
              <div class="ant-upload-text">
              </div>
            </div>
            <img src="@/assets/images/account/upload.svg" alt="" class="upload_img" v-if="!isMobile">
            <img src="@/assets/images/account/upload_mo.svg" alt="" class="upload_img" v-else>
          </a-upload>
        </template>
      </div>
      <div class="user_id">
        {{account|showAddress}}
        <img src="@/assets/images/search/share.svg" alt="" class="share" @click="doCopy">
      </div>
      <div class="copy_box">
        <div class="copy_id">{{account|showAddress}}</div>
        <img src="@/assets/images/account/copy_ico.svg" alt="" class="copy_ico" @click="onCopy">
      </div>
    </div>
    <searchList :account-page = true ></searchList>
  </div>
</template>

<script>
import searchList from '../components/search-list.vue'
import {mapState} from 'vuex'
import { userUpload, userAccount } from '@/api/index.js'
import nftSdk from "@/sdk";
import moment from "moment";
export default {
  name:'account',
  components:{
    searchList
  },
  data() {
    return {
      loading: false,
      imageUrl: '',
      imageUrl2: '',
      type:'background',
      account:this.$route.params.account
    }
  },
  mounted() {
    this.onLoadImage()
  },
  computed:{
    ...mapState({
      isMobile:(state) => state.isMobile
    }),
    is_owner(){
      if(this.account.toLowerCase()==this.$store.state.account){
        return true;
      }
      return false
    }
  },
  watch:{
    '$route.params.account': {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.account = this.$route.params.account;
          this.onLoadImage();
        }
      },
      deep: true,
    }
  },
  methods: {
    onLoadImage(){
      userAccount({account:this.account}).then( (data) => {
        this.imageUrl = data.extra.background;
        this.imageUrl2 = data.extra.avatar;
      })
      .catch(err => {console.log(err);})
    },
     beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error('Image must smaller than 10MB!');
      }
      return isLt2M;
    },
     avatarRequest(data){
       this.type = 'avatar';
       this.customRequest(data)
     },
     backgroundRequest(data){
       this.type = 'background';
       this.customRequest(data)
     },
    async customRequest(data){
      if(!this.account)return;
      let datetime = moment(new Date()).utc().format('YYYY-MM-DD HH:mm:ss');
      const msg = `datetime:${datetime}\ntype:${this.type}`;
      let signer = await nftSdk.getSigner();
      signer.signMessage(msg)
          .then((sign) => {
            const formData = new FormData()
            formData.append('file', data.file)
            formData.append('sign', sign)
            formData.append('address', this.account)
            formData.append('type', this.type)
            formData.append('datetime', datetime)
            userUpload(formData).then((data) => {
              if(this.type == 'avatar'){
                this.imageUrl2 = data.data.url||"";
              }
              if(this.type == 'background'){
                this.imageUrl = data.data.url||"";
              }
            }).catch(() => {

            });
          })
    },
    doCopy() {
      const message = window.location.href;
      this.$copyText(message).then(
          () => {
            this.$notification["success"]({
              message: "Link copied!",
            });
          },
          () => {
            this.$notification["error"]({
              message: "Link copy failed",
            });
          }
      );
    },
    onCopy() {
      this.$copyText(this.account).then(
          () => {
            this.$notification["success"]({
              message: "Account copied!",
            });
          },
          () => {
            this.$notification["error"]({
              message: "Account copy failed",
            });
          }
      );
    }
   },
}
</script>

<style lang="scss" scoped>
    .banner{
      width: 100%;
      min-width: 1200px;
      min-height: 310px;
      max-height: 310px;
      margin: 0 auto;
      overflow: hidden;
      position: relative;
      :deep(.avatar-uploader){
        position: absolute;
        z-index: 2;
        top: 0;
        left:0;
        .ant-upload-select-picture-card{
          width: 100%;
          height: 310px;
          background-color:transparent;
          border: none;
        }
        .upload_img{
          width: 40px;
          height: 40px;
          display: none;
          position: absolute;
          top: 50%;
          left:50%;
          margin-top: -20px;
        }
      }
      img{
        width: 100%;
        z-index: -1;
      }
      &.banner_hover{
        &::after{
          content: '';
          background-color: #072A2C;
          opacity: .8;
          z-index: 1;
          width: 100%;
          height: 310px;
          position: absolute;
          top: 0;
          left: 0;
          display: none;
        }
        &:hover::after{
          display: inline-block;
        }
        &:hover{
          .upload_img{
            display: inline-block;
          }
        }
      }

    }
    .user_box{
      width: 1200px;
      margin: 0 auto;
      position: relative;
      .user_img{
        width: 158px;
        height: 163px;
        background: url("~@/assets/images/account/userImg_bg.svg") no-repeat;
        position: absolute;
        overflow: hidden;
        left: 0;
        top: -135px;
        z-index: 3;
        :deep(.avatar-uploader){
        position: absolute;
        z-index: 4;
        top: 0;
        left:0;
        .ant-upload-select-picture-card{
          width: 100%;
          height: 148px;
          background-color:transparent;
          border: none;
          margin: 0;
          padding: 0;
        }
        .upload_img{
          width: 40px;
          height: 40px;
          display: none;
          position: absolute;
          top: 50%;
          left:50%;
          margin-top: -20px;
          margin-left: -20px;
        }
      }
        img{
          width: 100%;
          height: 100%;
          -webkit-mask-image: url("~@/assets/images/account/userImg_bg.svg");
          -webkit-mask-position: center;
          -webkit-mask-repeat: no-repeat;
          -webkit-mask-size: 93% ;
        }
        &.user_img_hover{
          &::after{
            content: '';
            background-color: #072A2C;
            opacity: .8;
            z-index: 3;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: none;
            -webkit-mask-image: url("~@/assets/images/account/userImg_bg.svg");
            -webkit-mask-position: center;
            -webkit-mask-repeat: no-repeat;
          }
          &:hover::after{
            display: inline-block;
          }
          &:hover{
            .upload_img{
              display: inline-block;
            }
          }
        }
      }
      .user_id{
        font-size: 30px;
        font-family: "SFPixelate-Bold";
        color: #FFFFFF;
        line-height: 32px;
        padding-top: 67px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .share{
          width: 40px;
          height: 40px;
        }
      }
      .copy_box{
        width: 186px;
        height: 38px;
        margin-top: 10px;
        background: url("~@/assets/images/account/copy_bg.svg") no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 68px;
        .copy_id{
          font-size: 14px;
          font-family: "SFPixelate-Bold";
          color: #FFFFFF;
          line-height: 16px;
        }
        .copy_ico{
          width: 18px;
          height: 18px;
          margin-left: 12px;
          cursor: pointer;
        }
      }
    }
    @media screen and(max-width:750px) {
      .banner{
        min-width: auto;
        max-height:1.88rem;
        min-height: 1.88rem;
        :deep(.avatar-uploader){
          .ant-upload-select-picture-card{
            height: 1.88rem;
            .upload_img{
              width: .46rem;
              height: auto;
              top: auto;
              bottom: .24rem;
              left: auto;
              right: .44rem;
              margin: 0;
            }
          }
        }
        &::after{
          height: 1.88rem;
        }
        img{
          object-fit: cover;
          height: 1.88rem;
        }
      }
      .user_box{
        width: 100%;
        padding: 0 .4rem;
         .user_img{
          width: 1.6rem;
          height: 1.6rem;
          background-size: contain;
          padding: .2rem;
          top: -1.1rem;
          left: .4rem;
          :deep(.avatar-uploader){
            .ant-upload-select-picture-card{
              height:1.6rem;
            }
            .upload_img{
              width: .36rem;
              height: auto;
              top: auto;
              bottom: .1rem;
              left: auto;
              right: .18rem;
              margin: 0;
            }
          }
         }
         .user_id{
          font-size: .4rem;
          line-height: .44rem;
          padding-top: 1.18rem;
          align-items: flex-start;
          .share{
            width: .8rem;
            height: .8rem;
          }
         }
          .copy_box{
            margin: 0 0 .6rem;
            width: 3.72rem;
            height: .76rem;
            background: url("~@/assets/images/account/copyBg_mo.svg") no-repeat;
            background-size: contain;
            .copy_id{
              font-size: .28rem;
              line-height: .32rem;
            }
            .copy_ico{
              width: 0.36rem;
              height: .36rem;
              margin-left: .24rem;
            }
          }
      }
    }
</style>
